@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans:wght@700&family=Noto+Sans+JP:wght@400;500;700&display=swap');

/* Tailwind基本スタイル */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* カスタム変数とベーススタイル */
@layer base {
  :root {
    /* カラーシステム */
    --background: 0 0% 100%;
    --foreground: 0 0% 13%; /* #222222 に相当する HSL 値 */
    --card: 0 0% 100%;
    --card-foreground: 0 0% 13%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 13%;
    --primary: 142 86% 28%;
    --primary-foreground: 356 29% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 0 0% 13%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 45%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 0 0% 13%;
    --destructive: 0 72% 51%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 142 86% 28%;

    /* テーマ設定 */
    --radius: 0.5rem;
    --theme-color: 142 86% 28%; /* #47B029 と同じ HSL 値 */
  }

  /* グローバルスタイル */
  * {
    @apply border-[hsl(var(--border))];
  }

  body {
    @apply bg-[hsl(var(--background))] text-text-default;
    font-feature-settings: "rlig" 1, "calt" 1;
  }

  /* フォント設定 */
  h2 {
    font-family: 'Montserrat', sans-serif;
  }

  h3 {
    font-family: 'Noto Serif JP', serif;
  }

  p, body {
    font-family: 'Noto Sans JP', sans-serif;
  }

  p {
    @apply leading-relaxed;
  }
}

/* ユーティリティクラス */
@layer utilities {
  /* テキストシャドウ */
  .shadow-text {
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  }
  .shadow-text-sm {
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  }

  /* フォント設定 */
  .font-heading {
    font-family: 'Montserrat', 'Noto Sans JP', sans-serif;
  }
  .font-body {
    font-family: 'Noto Sans JP', 'Open Sans', sans-serif;
  }
  .font-serif {
    font-family: 'Noto Serif JP', serif;
  }
  .font-open-sans {
    font-family: 'Open Sans', 'Noto Sans JP', sans-serif;
  }

  /* 日本語テキスト用クラス */
  .text-ja {
    font-size: 0.95em;
  }

  /* セクション共通スタイル */
  .section {
    @apply py-20 md:py-24;
  }

  .container {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
  }

  .gradient-text {
    @apply bg-clip-text text-transparent bg-gradient-to-r from-primary-600 to-primary-800;
  }

  .section-title {
    @apply text-4xl md:text-5xl font-bold mb-8 gradient-text font-heading;
    line-height: 1.2;
  }

  .section-subtitle {
    @apply text-xl text-gray-600 mb-12 font-body;
  }

  /* アニメーション */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .animate-fadeInUp {
    animation: fadeInUp 1.2s ease-out forwards;
    opacity: 0;
  }

  /* ラベルスタイル */
  .label {
    @apply inline-flex items-center px-2 py-0.5 text-xs font-bold rounded-full text-white ml-2 font-open-sans;
  }

  .label-new {
    @apply bg-hot-pink;
  }

  .label-beta {
    @apply bg-blue-500;
  }
}

@layer base {
  .font-montserrat {
    font-family: 'Montserrat', 'Noto Sans JP', sans-serif;
  }
}