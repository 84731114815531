.textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
}

.textarea:focus {
  outline: none;
  border-color: #0066cc;
}
