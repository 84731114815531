/* ヘッダー変数 */
:root {
  --header-height: 4rem;
  --logo-height: 48px;
  --transition-duration: 0.3s;
  --text-color-default: var(--text-default-color);
  --lang-switch-width: 90px;
}

/* ヘッダー基本スタイル */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--header-height);
  z-index: 50;
  background-color: transparent;
  transition: background-color var(--transition-duration) ease-in-out, 
              box-shadow var(--transition-duration) ease-in-out;
}

.headerScrolled {
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

/* コンテナスタイル */
.container {
  max-width: 1440px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
}

/* ロゴスタイル */
.logo {
  font-size: 1.5rem;
  font-weight: bold;
  flex-shrink: 0;
}

.logoWrapper {
  position: relative;
  height: var(--logo-height);
  display: flex;
  align-items: center;
}

.logoImage {
  height: 100%;
  width: auto;
  object-fit: contain;
  object-position: left center;
  transition: opacity var(--transition-duration) ease-in-out;
}

/* ナビゲーションスタイル */
.nav {
  display: none; /* モバイルでは非表示、デスクトップで表示 */
}

.navLink {
  font-weight: bold;
  transition: color var(--transition-duration) ease-in-out;
  text-decoration: none;
  position: relative;
  padding: 0.5rem 0.75rem;
  background: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

/* ナビゲーションリンクのホバーエフェクト */
.navLink::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: hsl(var(--theme-color));
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform var(--transition-duration) ease-in-out;
}

.navLink:hover {
  color: hsl(var(--theme-color));
}

.navLink:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* 言語切替ボタン（デスクトップ） */
.langSwitchDesktop {
  width: var(--lang-switch-width);
  justify-content: center;
}

.globeIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

/* モバイルボタン共通スタイル */
.mobileButtons {
  display: flex;
  align-items: center;
}

.mobileButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  color: var(--text-color-default);
  transition: color var(--transition-duration) ease-in-out;
  margin-left: 0rem;
  width: 60px;
}

.mobileButtonLight {
  color: white;
}

.mobileGlobeIcon {
  width: 22px;
  height: 22px;
  margin-top: 2px;
}

/* モバイルメニュースタイル */
.mobileMenu {
  position: fixed;
  top: var(--header-height);
  right: 0;
  width: 100%;
  height: calc(100vh - var(--header-height));
  background-color: white;
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  overflow-y: auto;
  z-index: 50;
  transform: translateX(100%);
}

.mobileMenuOpen {
  transform: translateX(0);
}

.mobileNavLink {
  display: block;
  padding: 1rem 2rem;
  text-decoration: none;
  border-bottom: 1px solid #e5e7eb;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.mobileMenuOpen .mobileNavLink {
  opacity: 1;
  transform: translateY(0);
}

.mobileNavTitle {
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

/* 各ナビゲーションリンクのアニメーションディレイ */
.mobileMenuOpen .mobileNavLink:nth-child(1) { transition-delay: 0.1s; }
.mobileMenuOpen .mobileNavLink:nth-child(2) { transition-delay: 0.2s; }
.mobileMenuOpen .mobileNavLink:nth-child(3) { transition-delay: 0.3s; }
.mobileMenuOpen .mobileNavLink:nth-child(4) { transition-delay: 0.4s; }
.mobileMenuOpen .mobileNavLink:nth-child(5) { transition-delay: 0.5s; }
.mobileMenuOpen .mobileNavLink:nth-child(6) { transition-delay: 0.6s; }
.mobileMenuOpen .mobileNavLink:nth-child(7) { transition-delay: 0.7s; }

/* レスポンシブデザイン */
/* デスクトップスタイル */
@media (min-width: 1100px) {
  .container {
    padding: 0 2rem;
  }

  .nav {
    display: flex;
  }

  .nav > * + * {
    margin-left: 0.5rem;
  }

  .mobileButtons {
    display: none;
  }

  .mobileMenu {
    display: none;
  }

  .globeIcon {
    width: 22px;
    height: 22px;
  }

  .langSwitchDesktop {
    width: auto;
    padding: 0 0.75rem;
  }
}

/* モバイルスタイル */
@media (max-width: 1099px) {
  :root {
    --header-height: 3.5rem;
    --logo-height: 43.2px;
  }

  .container {
    padding: 0 0.5rem;
  }

  .logoWrapper {
    padding-top: 0.25rem;
  }

  .nav {
    display: none;
  }

  .mobileGlobeIcon {
    width: 22px;
    height: 22px;
    margin-top: 2px;
  }
}

/* 小さな画面幅のデバイス用 */
@media (max-width: 360px) {
  .logo {
    max-width: 60%;
  }

  .mobileButton {
    margin-left: 0.25rem;
    width: 45px;
  }
}

/* 特大画面サイズのスタイル */
@media (min-width: 1536px) {
  :root {
    --header-height: 5rem;
    --logo-height: 57.6px;
    --lang-switch-width: 100px;
  }

  .container {
    padding: 0 2rem;
    max-width: 90%;
  }

  .navLink {
    font-size: 1.125rem;
    padding: 0.75rem 0.875rem;
  }

  .nav {
    gap: 0.75rem;
  }

  .globeIcon {
    width: 26px;
    height: 26px;
  }
}

@media (min-width: 1920px) {
  :root {
    --header-height: 6rem;
    --logo-height: 64px;
    --lang-switch-width: 110px;
  }

  .container {
    padding: 0 2rem;
    max-width: 85%;
  }

  .navLink {
    font-size: 1.25rem;
    padding: 1rem 1rem;
  }

  .nav {
    gap: 1rem;
  }

  .globeIcon {
    width: 26px;
    height: 26px;
  }
}